/**
 * @copyright 2020 @ DigiNet
 * @author THIEN
 * @create 7/4/2020
 * @Example
 */

export const types = {
    W39F1012_GET_FORM: "W39F1012_GET_FORM",
    W39F1012_GET_FORM_SUCCESS: "W39F1012_GET_FORM_SUCCESS",
};

export function getForm(params, cb) {
    return {
        type: types.W39F1012_GET_FORM,
        cb,
        params,
    };
}

