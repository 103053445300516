/**
 * @copyright 2021 @ DigiNet
 * @author QUOCUY
 * @create 4/20/2021
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, take, takeLatest } from "redux-saga/effects";
import Api from "../../../services/api";
import { types } from "./W39F3010_actions";

export default function W39F3010Sagas() {
    return [watchLoadGrid(), watchStatus(), watchDeleteRow(), watchGetComboGoalTable()];
}

export function* loadStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w39f3010/load-status", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3010_LOAD_STATUS, loadStatus);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* loadGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w39f3010/load-grid", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchLoadGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3010_LOAD_GRID, loadGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* deleteRow(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w39f3010/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchDeleteRow() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3010_DELETE, deleteRow);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getComboGoalTable(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/W39F3001/load-table-target');
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get combo goal table error', e);
    }
}

export function* watchGetComboGoalTable() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3010_GET_COMBO_GOAL_TABLE, getComboGoalTable);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
