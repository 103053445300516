/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 19/8/2021
 * @Example
 */


export const types = {
    W39F3025_GET_GOAL_TABLE: 'W39F3025_GET_GOAL_TABLE',
    W39F3025_GET_DETAIL_DATA: 'W39F3025_GET_DETAIL_DATA',
};

export function getGoalTable(cb) {
    return {
        type: types.W39F3025_GET_GOAL_TABLE,
        cb,
    };
}

export function getDetailData(params, cb) {
    return {
        type: types.W39F3025_GET_DETAIL_DATA,
        params,
        cb,
    };
}