/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 4/1/2021
 * @Example 
 */

import { types } from "./W39F3001_actions";

const initialState = {
    getGroupTargets: [],
};

export default function (state = initialState, action = {}) {
    if (action.type === types.W39F3001_GET_GROUP_TARGET_SUCCESS) {
        return {
            ...state,
            getGroupTargets: action.data
        };
    } else {
        return state;
    }
}
