/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 7/13/2020
 * @Example 
 */
export const types = {
    W39F2001_GET_CONTRACT_TYPES: "W39F2001_GET_CONTRACT_TYPES",
    W39F2001_GET_CONTRACT_TYPES_SUCCESS: "W39F2001_GET_CONTRACT_TYPES_SUCCESS",
    W39F2001_GET_DATAGRID: "W39F2001_GET_DATAGRID",
    W39F2001_GET_DATAGRID_SUCCESS: "W39F2001_GET_DATAGRID_SUCCESS",
    W39F2001_DELETE_DATA: "W39F2001_DELETE_DATA",
};

export function getContractTypes(cb) {
    return {
        type: types.W39F2001_GET_CONTRACT_TYPES,
        cb
    }
}

export function getDataGrids(params, cb) {
    return {
        type: types.W39F2001_GET_DATAGRID,
        params,
        cb
    }
}
