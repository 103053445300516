/**
 * @copyright 2020 @ DigiNet
 * @author THIEN
 * @create 6/1/2020
 * @Example
 */

import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W39F1010_actions";
import { delay } from "redux-saga";

export default function W39F1010Sagas() {
    return [watchGetFormGrid(), watchGetTransType()];
}

export function* getFormGrid(data) {
    try {
        yield delay(300);
        const res = yield Api.put("/w39f1010/load-form-grid", data.params);
        if (res && res.data) {
            yield put({
                type: types.W39F1010_GET_FORM_GRID_SUCCESS,
                data: res.data,
            });
            data.cb && data.cb(null, res.data);
            return;
        } else {
       
            data.cb && data.cb(res, null);
        }
    } catch (e) {}
}

export function* watchGetFormGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1010_GET_FORM_GRID, getFormGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getTransType(data) {
    try {
        yield delay(300);
        const res = yield Api.get("/w39f1010/get-trans-type");
        if (res && res.data) {
            yield put({
                type: types.W39F1010_GET_TRANS_TYPE_SUCCESS,
                data: res.data,
            });
            data.cb && data.cb(null, res.data);
            return;
        } else {
            data.cb && data.cb(res, null);
        }
    } catch (e) {}
}

export function* watchGetTransType() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1010_GET_TRANS_TYPE, getTransType);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
