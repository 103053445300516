/**
 * @copyright 2020 @ DigiNet
 * @author THIEN
 * @create 7/4/2020
 * @Example
 */

import { types } from "./W39F1012_actions";

const initialState = {
    getForm: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W39F1012_GET_FORM_SUCCESS: {
            return { ...state, getForm: action.data };
        }
        default: {
            return state;
        }
    }
}
