/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 7/13/2020
 * @Example 
 */
export const types = {
    W39F2000_GET_DATAGRID: "W39F2000_GET_DATAGRID",
    W39F2000_GET_DATAGRID_SUCCESS: "W39F2000_GET_DATAGRID_SUCCESS",
};

export function getDataGrids(params, cb) {
    return {
        type: types.W39F2000_GET_DATAGRID,
        params,
        cb
    }
}
