/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 16/09/2021
 * @Example
 */

export const types = {
  W39F3035_GET_DATA_GRID: "W39F3035_GET_DATA_GRID",
  W39F3035_GET_DATA_GRID_SUCCESS: "W39F3035_GET_DATA_GRID_SUCCESS",
};

export function getDataGrid(params, cb) {
  return {
    type: types.W39F3035_GET_DATA_GRID,
    params,
    cb
  };
}

