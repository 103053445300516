/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 23/3/2021
 * @Example
 */

import { types } from './W39F3000_actions';

const initialState = {
    data: {
        rows: [],
        total: 0,
    },
    detailData: {},
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W39F3000_GET_DATA_SUCCESS:
            return { ...state, data: action?.data ?? { rows: [], total: 0 } };
        case types.W39F3000_GET_DETAIL_DATA_SUCCESS:
            return { ...state, detailData: action?.data?.[0] ?? {} };
        default:
            return state;
    }
}
