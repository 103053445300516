/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 7/6/2020
 * @Example
 */

import { types } from "./W39F1025_actions";

const initialState = {
    getEvaluationType: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W39F1025_GET_EVALUATION_TYPE_SUCCESS: {
            return { ...state, getEvaluationType: action.data };
        }
        default: {
            return state;
        }
    }
}
