/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 23/3/2021
 * @Example
 */

export const types = {
    W39F3005_CHECK_TABLE: "W39F3005_CHECK_TABLE",
    W39F3005_CHECK_EXIST: "W39F3005_CHECK_EXIST",
    W39F3005_SAVE: "W39F3005_SAVE",
    W39F3005_CHECK: "W39F3005_CHECK",
};

export function checkTable(param, cb) {
    return {
        type: types.W39F3005_CHECK_TABLE,
        param,
        cb
    }
};

export function checkExist(param, cb) {
    return {
        type: types.W39F3005_CHECK_EXIST,
        param,
        cb
    }
};

export function save(param, cb) {
    return {
        type: types.W39F3005_SAVE,
        param,
        cb
    }
};

export function check(param, cb) {
    return {
        type: types.W39F3005_CHECK,
        param,
        cb
    }
};