/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 4/10/2021
 * @Example
 */

import Api                               from '../../../services/api';
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types }                         from "./W39F3020_actions";
import { delay }                         from "redux-saga";

export default function W39F3020Sagas() {
    return [
        watchGetCboTargetTable(),
        watchHandelBrowsing(),
        watchGetDataGrid(),
    ];
}

export function* getCboTargetTable(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w39f3020/get-cbo-target-table');
        if (response && response.data) {
            yield put({ type: types.W39F3020_GET_CBO_TARGET_TABLE_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo target table error');
    }

}

export function* watchGetCboTargetTable() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3020_GET_CBO_TARGET_TABLE, getCboTargetTable);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getHandleBrowsing(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3020/handle-browsing', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get handle browsing error');
    }

}

export function* watchHandelBrowsing() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3020_GET_HANDEL_BROWSING, getHandleBrowsing);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3020/load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get data grid error');
    }

}

export function* watchGetDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3020_GET_DATA_GRID, getDataGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
