/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 23/3/2021
 * @Example
 */

import Api from '../../../services/api';
import { cancel, put, take, takeLatest } from 'redux-saga/effects';
import { types } from './W39F3000_actions';
import { delay } from 'redux-saga';

export default function W39F3000Sagas() {
    return [
        watchGetComboStatusGoal(),
        watchGetComboStatusNews(),
        watchGetTargetResult(),
        watchFinishGoalPlan(),
        watchGetData(),
        watchGetComboGoalTable(),
        watchGetComboStatusGoalPlan(),
        watchGetDetailData(),
        watchSaveStandardApproval(),
    ];
}

export function* getComboStatusGoal(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W39F3000/load-cbo-status-goal', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get combo status goal error', e);
    }
}

export function* watchGetComboStatusGoal() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3000_GET_COMBO_STATUS_GOAL, getComboStatusGoal);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getComboStatusNews(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/W39F3000/load-cbo-status-news');
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get combo status news error');
    }
}

export function* watchGetComboStatusNews() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3000_GET_COMBO_STATUS_NEWS, getComboStatusNews);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getTargetResult(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W39F3000/load-target-result', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get target result error');
    }
}

export function* watchGetTargetResult() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3000_GET_TARGET_RESULT, getTargetResult);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* finishGoalPlan(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W39F3000/get-success', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get success error');
    }
}

export function* watchFinishGoalPlan() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3000_FINISH_GOAL_PLAN, finishGoalPlan);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W39F3000/load-form', data.param);
        if (response && response.data) {
            yield put({ type: types.W39F3000_GET_DATA_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get data error');
    }
}

export function* watchGetData() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3000_GET_DATA, getData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getComboGoalTable(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/W39F3001/load-table-target');
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get combo goal table error', e);
    }
}

export function* watchGetComboGoalTable() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3000_GET_COMBO_GOAL_TABLE, getComboGoalTable);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getComboStatusGoalPlan(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W39F3000/load-cbo-status-goal', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get combo status goal plan error', e);
    }
}

export function* watchGetComboStatusGoalPlan() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3000_GET_COMBO_STATUS_GOAL_PLAN, getComboStatusGoalPlan);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getDetailData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/W39F3000/load-form', data.param);
        if (response && response.data) {
            yield put({ type: types.W39F3000_GET_DETAIL_DATA_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get detail data error');
    }
}

export function* watchGetDetailData() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3000_GET_DETAIL_DATA, getDetailData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* saveStandardApproval(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/aproval', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('save data approval error');
    }
}

export function* watchSaveStandardApproval() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3000_SAVE_STANDARD_APPROVAL, saveStandardApproval);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

