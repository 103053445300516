/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 7/16/2020
 * @Example
 */

import Api from '../../../services/api';
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W39F1030_actions";
import { delay } from "redux-saga";

export default function W39F1030Sagas() {
    return [

        watchGetLoadFormGrid(),
        watchGetLoadListAppChildCriterion(),
        watchGetCboAppChildCriterion(),
        watchSave()
    ];
}


export function* getLoadFormGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1030/load-form-grid', data.params);
        if (response && response.data) {
            yield put({ type: types.W39F1030_GET_LOAD_FORM_GRID_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get load form grid error');
    }

}

export function* watchGetLoadFormGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1030_GET_LOAD_FORM_GRID, getLoadFormGrid);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getLoadListAppChildCriterion(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1030/load-list-app-child-criterion',  data.params);
        if (response && response.data) {
            yield put({ type: types.W39F1030_GET_LOAD_LIST_APP_CHILD_CRITERION_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get load list app child criterion error', e);
    }

}

export function* watchGetLoadListAppChildCriterion() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1030_GET_LOAD_LIST_APP_CHILD_CRITERION, getLoadListAppChildCriterion);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboAppChildCriterion(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1030/load-cbo-app-criterion',  data.params);
        if (response && response.data) {
            yield put({ type: types.W39F1030_GET_CBO_APP_CHILD_CRITERION_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo app child criterion error');
    }

}

export function* watchGetCboAppChildCriterion() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1030_GET_CBO_APP_CHILD_CRITERION, getCboAppChildCriterion);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1030/save',  data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save data error');
    }

}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1030_SAVE, save);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
