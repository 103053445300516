/**
 * @copyright 2020 @ DigiNet
 * @author CanhToan
 * @create 07/02/2020
 * @Example
 */

import Api from '../../../services/api';
import { cancel, take, put, takeLatest } from "redux-saga/effects";
import { types } from "./W39F1005_actions";
import { delay } from "redux-saga";

export default function W39F1000Sagas() {
    return [
        watchloadCboTransType(),
        watcLoadDetail(),
        watchAdd(),
        watchEdit(),
        watchRemoveItem(),
        watchUpdateLock(),
    ];
}

export function* loadTransType(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1005/load-evaluation-type', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response)
        }
    }
    catch (e) {
        console.log('Load trans type error');
    }

}

export function* watchloadCboTransType() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1005_LOAD_TRANS_TYPE, loadTransType);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadDetail(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1005/load-detail', data.params);
        if (response && response.data) {
            yield put({type: types.W39F1005_LOAD_DETAIL_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response)
        }
    }
    catch (e) {
        console.log('Det data detail error');
    }

}

export function* watcLoadDetail() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1005_LOAD_DETAIL, loadDetail);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* add(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1005/add', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response);
        }
    }
    catch (e) {
        console.log('Det data detail error');
    }

}

export function* watchAdd() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1005_ADD, add);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* edit(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1005/edit', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response);
        }
    }
    catch (e) {
        console.log('Det data detail error');
    }

}

export function* watchEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1005_EDIT, edit);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* removeItem(data) {
    try {
        yield delay(300);
        yield put({type: types.W39F1005_REMOVE_ITEM_SUCCESS, data: data.params});
        data.cb && data.cb(null);
    }
    catch (e) {
        console.log('Remove item error');
    }

}

export function* watchRemoveItem() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1005_REMOVE_ITEM, removeItem);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* updateLock(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1005/update-clock', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response);
        }
    }
    catch (e) {
        console.log('Update lock error');
    }

}

export function* watchUpdateLock() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1005_UPDATE_LOCK, updateLock);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
