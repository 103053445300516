/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 23/3/2021
 * @Example
 */

import Api from '../../../services/api';
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W39F3005_actions";
import { delay } from "redux-saga";

export default function W39F3005Sagas() {
    return [
        watchCheckTable(),
        watchCheckExist(),
        watchSave(),
        watchCheck()
    ];
};

export function* checkTable(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3005/check-table', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('check table error', e);
    }
};

export function* watchCheckTable() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3005_CHECK_TABLE, checkTable);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
};

export function* checkExist(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3005/check-exist', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('check exist error', e);
    }
};

export function* watchCheckExist() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3005_CHECK_EXIST, checkExist);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
};

export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3005/save', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save error', e);
    }
};

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3005_SAVE, save);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
};

export function* check(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3005/check', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('check error', e);
    }
};

export function* watchCheck() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3005_CHECK, check);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
};