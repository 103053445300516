export const types = {
    W39F1031_SAVE: "W39F1031_SAVE",
};


export function save(params,cb) {
    return{
        type: types.W39F1031_SAVE,
        params,
        cb
    }
}

