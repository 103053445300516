/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 7/6/2020
 * @Example
 */

import Api from '../../../services/api';
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "../../../redux/W3X/W39F1031/W39F1031_actions";
import { delay } from "redux-saga";

export default function W39F1031Sagas() {
    return [
        watchSave(),
    ];
}

//save..
export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f1031/save', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save error');
    }

}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W39F1031_SAVE, save);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}