/***********************************************************************/
/*                                                                     */
/*   Copyright (C) 2020. All rights reserved                           */
/*   License    : https://diginet.com.vn                               */
/*   Author     : RocaChien                                            */
/*                                                                     */
/*   Created    : 09-07-2020 10:13:05.                                 */
/*   Modified   : 09-07-2020 10:13:05.                                 */
/*                                                                     */
/***********************************************************************/

import Api from "../../../services/api";

export const types = {
  W39F1011_LOAD_MASTER_DATA: "W39F1011_LOAD_MASTER_DATA",
  W39F1011_LOAD_CRITERION_GROUP: "W39F1011_LOAD_CRITERION_GROUP",
  W39F1011_LOAD_CRITERION_COMBO: "W39F1011_LOAD_CRITERION_COMBO",
  W39F1011_LOAD_CRITERION_DETAIL: "W39F1011_LOAD_CRITERION_DETAIL",
  W39F1011_LOAD_COMBO_RESULTS: "W39F1011_LOAD_COMBO_RESULTS",
  W39F1011_LOAD_TYPE_EVALUATION: "W39F1011_LOAD_TYPE_EVALUATION",
  W39F1011_LOAD_HISTORY_APPROVAL: "W39F1011_LOAD_HISTORY_APPROVAL",
  W39F1011_SAVE: "W39F1011_SAVE",

  W39F1011_LOAD_MASTER_DATA_SUCCESS: "W39F1011_LOAD_MASTER_DATA_SUCCESS",
  W39F1011_LOAD_CRITERION_GROUP_SUCCESS: "W39F1011_LOAD_CRITERION_GROUP_SUCCESS",
  W39F1011_LOAD_CRITERION_COMBO_SUCCESS: "W39F1011_LOAD_CRITERION_COMBO_SUCCESS",
  W39F1011_LOAD_CRITERION_DETAIL_SUCCESS: "W39F1011_LOAD_CRITERION_DETAIL_SUCCESS",
  W39F1011_LOAD_COMBO_RESULTS_SUCCESS: "W39F1011_LOAD_COMBO_RESULTS_SUCCESS",
  W39F1011_LOAD_HISTORY_APPROVAL_SUCCESS: "W39F1011_LOAD_HISTORY_APPROVAL_SUCCESS",
  W39F1011_SAVE_SUCCESS: "W39F1011_SAVE_SUCCESS",
  W39F1011_GET_RESULT: "W39F1011_GET_RESULT",
};

export function getTypeEvaluation(params, cb) {

  return async () => {
    return await Api.put('/w39f1011/load-combo-evaluation-Level', params)
        .then(result => {
          if (result && result.code && result.code !== 200) {
            cb && cb(result);
            return null;
          } else if (result.data) {
              cb && cb(result.data);
              return result.data;
          } else {
            cb && cb(result);
            return null;
          }
        });
  }
}

export function loadComboResults(params, cb) {
  return {
    type: types.W39F1011_LOAD_COMBO_RESULTS,
    params,
    cb
  };
}

export function loadMasterData(params, cb) {
  return {
    type: types.W39F1011_LOAD_MASTER_DATA,
    params,
    cb
  };
}

export function loadCriterionGroup(params, cb) {
  return {
    type: types.W39F1011_LOAD_CRITERION_GROUP,
    params,
    cb
  };
}

export function loadCriterionCombo(params, cb) {
  return {
    type: types.W39F1011_LOAD_CRITERION_COMBO,
    params,
    cb
  };
}

export function loadCriterionDetail(params, cb) {
  return {
    type: types.W39F1011_LOAD_CRITERION_DETAIL,
    params,
    cb
  };
}

export function loadHistoryApproval(params, cb) {
  return {
    type: types.W39F1011_LOAD_HISTORY_APPROVAL,
    params,
    cb
  };
}

export function save(params, cb) {
  return {
    type: types.W39F1011_SAVE,
    params,
    cb
  };
}

export function getResult(params, cb) {
  return {
    type: types.W39F1011_GET_RESULT,
    params,
    cb
  };
}
