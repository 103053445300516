/**
 * @copyright 2021 @ DigiNet
 * @author QUOCUY
 * @create 4/20/2021
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, take, takeLatest } from "redux-saga/effects";
import Api from "../../../services/api";
import { types } from "./W39F3011_actions";

export default function W39F3011Sagas() {
    return [watchLoadPopup(), watchSave(), watchDeleteRow()];
}

export function* loadPopup(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w39f3011/load-popup", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchLoadPopup() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3011_LOAD_POPUP, loadPopup);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* deleteRow(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w39f3011/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchDeleteRow() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3011_DELETE_ROW, deleteRow);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.post("/w39f3011/save", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3011_SAVE, save);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
