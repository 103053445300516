/**
 * @copyright 2020 @ DigiNet
 * @author CanhToan
 * @create 07/02/2020
 * @Example
 */

import { types } from "./W39F1005_actions";

const initialState = {
    dataDetail: [],
    dataTransType: []
};

export default function(state = initialState, action = {}) {
    switch (action.type) {
        case types.W39F1005_LOAD_DETAIL_SUCCESS: {
            return {
                ...state,
                dataDetail: state.dataDetail.concat(action.data)
            };
        }
        case types.W39F1005_REMOVE_ITEM_SUCCESS: {
            const dataDetail = state.dataDetail.filter((v, i) => i !== action.data);
            return {
                ...state,
                dataDetail: dataDetail
            };
        }
        default:
            return state;
    }
}
