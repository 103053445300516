export const types = {
    W39F1025_GET_EVALUATION_TYPE: 'W39F1025_GET_EVALUATION_TYPE',
    W39F1025_GET_EVALUATION_TYPE_SUCCESS: 'W39F1025_GET_EVALUATION_TYPE_SUCCESS',
    W39F1025_GET_DETAIL: "W39F1025_GET_DETAIL",
    W39F1025_GET_DETAIL_SUCCESS: "W39F1025_GET_DETAIL_SUCCESS",
    W39F1025_ADD: "W39F1025_ADD",
    W39F1025_EDIT: "W39F1025_EDIT",
};

export function getEvaluationType(params,cb) {
    return{
        type: types.W39F1025_GET_EVALUATION_TYPE,
        params,
        cb
    }
}

export function getDetail(params,cb) {
    return{
        type: types.W39F1025_GET_DETAIL,
        params,
        cb
    }
}

export function add(params,cb) {
    return{
        type: types.W39F1025_ADD,
        params,
        cb
    }
}

export function edit(params,cb) {
    return{
        type: types.W39F1025_EDIT,
        params,
        cb
    }
}

