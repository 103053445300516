/***********************************************************************/
/*                                                                     */
/*   Copyright (C) 2020. All rights reserved                           */
/*   License    : https://diginet.com.vn                               */
/*   Author     : RocaChien                                            */
/*                                                                     */
/*   Created    : 09-07-2020 10:13:05.                                 */
/*   Modified   : 09-07-2020 10:13:05.                                 */
/*                                                                     */
/***********************************************************************/

import {types} from "./W39F1011_actions";

const initialState = {
    dataMasterData: [],
    dataCriterionGroup: [],
    dataCriterionCombo: [],
    dataCriterionDetail: [],
    dataComboResults: [],
    dataSaveResult: [],
    dataHistoryApproval: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W39F1011_LOAD_MASTER_DATA_SUCCESS: {
            return {...state, dataMasterData: action.data};
        }
        case types.W39F1011_LOAD_CRITERION_GROUP_SUCCESS: {
            return {...state, dataCriterionGroup: action.data};
        }
        case types.W39F1011_LOAD_CRITERION_COMBO_SUCCESS: {
            return {...state, dataCriterionCombo: action.data};
        }
        case types.W39F1011_LOAD_CRITERION_DETAIL_SUCCESS: {
            return {...state, dataCriterionDetail: action.data};
        }
        case types.W39F1011_LOAD_COMBO_RESULTS_SUCCESS: {
            return {...state, dataComboResults: action.data};
        }
        case types.W39F1011_LOAD_HISTORY_APPROVAL_SUCCESS: {
            return {...state, dataHistoryApproval: action.data};
        }
        case types.W39F1011_SAVE_SUCCESS: {
            return {...state, dataSaveResult: action.data};
        }
        default:
            return state;
    }
}
