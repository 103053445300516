/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 4/1/2021
 * @Example 
 */

import Api                               from '../../../services/api';
import { cancel, put, take, takeLatest, takeEvery } from "redux-saga/effects";
import { types }                         from "./W39F3001_actions";
import { delay }                         from "redux-saga";

export default function W39F3001Sagas() {
    return [
        watchGetTableTarget(),
        watchGetGroupTarget(),
        watchGetCboEmployees(),
        watchSave(),
        watchGetCboGoalName(),
        watchGetCaptions(),
        watchGetCboConcurrent(),
        watchCheckEmployeeConcurrent(),
    ];
}

export function* getCaptions(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w39f3001/get-refCaption');
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get captions error');
    }

}

export function* watchGetCaptions() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3001_GET_CAPTIONS, getCaptions);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getTableTarget(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w39f3001/load-table-target');
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get table target error');
    }

}

export function* watchGetTableTarget() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3001_GET_TABLE_TARGET, getTableTarget);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getGroupTarget(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3001/load-group-target', data.params);
        if (response && response.data) {
            yield put({ type: types.W39F3001_GET_GROUP_TARGET_SUCCESS, data: response.data  });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get group target error');
    }

}

export function* watchGetGroupTarget() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3001_GET_GROUP_TARGET, getGroupTarget);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboEmployees(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/standard/load-cbo-employee', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo employees error');
    }
}

export function* watchGetCboEmployees() {
    while (true) {
        const watcher = yield takeEvery(types.W39F3001_GET_CBO_EMPLOYEE, getCboEmployees);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboGoalName(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3001/load-cbo-name-target', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo goal name error');
    }
}

export function* watchGetCboGoalName() {
    while (true) {
        const watcher = yield takeEvery(types.W39F3001_GET_CBO_GOAL_NAME, getCboGoalName);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboConcurrent(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3000/load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo concurrent error');
    }
}

export function* watchGetCboConcurrent() {
    while (true) {
        const watcher = yield takeEvery(types.W39F3001_GET_CBO_CONCURRENT, getCboConcurrent);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.post('/w39f3001/save', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save data error');
    }

}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3001_SAVE, save);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCheckEmployeeConcurrent(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3001/check-employee-concurrent', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('check employee concurrent error');
    }
}

export function* watchCheckEmployeeConcurrent() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3001_GET_CHECK_EMPLOYEE_CONCURRENT, getCheckEmployeeConcurrent);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

