/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 7/13/2020
 * @Example
 */

import { types } from "./W39F2000_actions";

const initialState = {
    getDataGrids: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W39F2000_GET_DATAGRID_SUCCESS:
            return { ...state, getDataGrids: action.data };
        default:
            return state;
    }
}
